import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

// Disable right-click and keyboard shortcuts
document.addEventListener('contextmenu', (event) => event.preventDefault());
document.addEventListener('keydown', (event) => {
  if (event.key === 'F12' || (event.ctrlKey && event.shiftKey && event.key === 'I')) {
    event.preventDefault();
  }
});
// Detect and warn if DevTools are open
function detectDevTools() {
  const devTools = {
    isOpen: false,
    orientation: null,
  };
  const threshold = 160;
  const emitEvent = (isOpen, orientation) => {
    devTools.isOpen = isOpen;
    devTools.orientation = orientation;
    if (isOpen) {
      //alert('Please close developer tools to proceed.');
    }
  };
  setInterval(() => {
    const widthThreshold = window.outerWidth - window.innerWidth > threshold;
    const heightThreshold = window.outerHeight - window.innerHeight > threshold;
    const isOpen = widthThreshold || heightThreshold;
    const orientation = widthThreshold ? 'vertical' : 'horizontal';
    if (isOpen && (devTools.isOpen !== isOpen || devTools.orientation !== orientation)) {
      emitEvent(isOpen, orientation);
    }
  }, 500);
}
detectDevTools();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.unregister();